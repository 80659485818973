import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/Hero'
import Main from '../components/Main'
import styled from 'react-emotion'
import Button from '../components/Button'
import imgServices from '../assets/services.jpg'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import Panel from '../components/panel'

export default () => (
  <Layout>
    <Helmet>
      <title>Cruisio: Services</title>
    </Helmet>
    <Hero
      cover={imgServices}
      headText="Services"
      subheadText="Active fuel savings technology delivered autonomously."></Hero>
    <Main>
      <Panel>
        <h1>Reflecting Nature</h1>
        <p>Pulsed Cruise<sup>TM</sup> Technology was modelled off nature which uses momentum in combination with pulsed drive systems to allow biological systems to use energy efficiently and travel further.</p>
      </Panel>
      <Panel>
        <h1>Theory to Practice</h1>
        <p>Mathematical proofs, lab tested validation and real-world rubber on the road driving tests, patented Pulsed Cruise™ technology delivers results.</p>
      </Panel>
      <Panel>
        <h1>Designed to Drive</h1>
        <p>A simple upload of software algorithms to the ECU provides automated micro hypermiling to dynamically control fuel consumption autonomously with essentially imperceptible speed variation.</p>
      </Panel>
      <Panel>
        <h1>Immediate Impact</h1>
        <p>Fuel efficiency of 3-18% is created with every mile (kilometer) driven - Less expense and less environmental impact.</p>
      </Panel>
      <Panel>
        <h1>Learn More</h1>
        <p>Our services include:</p>
        <ul>
          <li className="strong">Vehicle Optimization</li>
          <li className="strong">Fleet Fuel Performance</li>
        </ul>
      </Panel>
    </Main>
  </Layout>
)
